import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InviteMemberDialogBox from "../DialogBox/InviteMemberDialogBox";
import MembersTable from "../Table/MembersTable";
import Cookies from "js-cookie";
import WorkspaceNameEdit from "../DialogBox/WorkspaceNameEdit";
import { getWorkspaceTeam } from "../../redux/actions/workspaceActions";
import SamlDialogBox from "../DialogBox/SamlDialogBox";

export function UsersSettings() {
    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.user.userData?.is_admin);
    const teamDetails = useSelector((state) => state.workspace.teamDetails);
    const team = useSelector((state) => state.user.userData?.team);

    const role_str = Cookies.get("role");
    const role = role_str ? JSON.parse(role_str) : null;
    const [workspaceDetail, setWokspaceDetail] = useState(role?.team);
    const workspaceDetailEdit = (data) => {
        setWokspaceDetail(data);
    };

    useEffect(() => {
        dispatch(getWorkspaceTeam());
    }, [dispatch, isAdmin]);

    // console.log("teamDetails ==>", teamDetails);

    return (
        <div className="user_page">
            <div className="section_workspace" style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div
                        className="workspace_title_main"
                        style={{ display: "flex", gap: "5px" }}
                    >
                        <h2>{team?.name}</h2>
                        {team ? (
                            <WorkspaceNameEdit
                                openFor={"edit"}
                                workspaceDetailEdit={workspaceDetailEdit}
                            />
                        ) : (
                            <WorkspaceNameEdit openFor={"add"} />
                        )}
                    </div>
                    <InviteMemberDialogBox />
                </div>
                <span style={{ fontSize: "16px" }}>{team?.description}</span>
                {teamDetails?.enterprise && (
                    <div style={{ fontSize: "16px" }}>
                        Total Question asked by Team:{" "}
                        {teamDetails?.num_questions || 0}
                    </div>
                )}
            </div>
            <div className="table_area mt-2">
                <MembersTable />
            </div>
        </div>
    );
}